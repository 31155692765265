var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{ref:"userAvatarImage",staticClass:"avatar",class:( _obj = {
      lazy: _vm.lazy
    }, _obj[_vm.avatarSizeClass] = true, _obj[_vm.cssClasses] = true, _obj ),attrs:{"src":_vm.resultantSrcAttribute,"width":_vm.size,"height":_vm.size,"alt":_vm.imgAlt,"data-src":_vm.sanitizedSource}}),_vm._v(" "),(
      _vm.tooltipText ||
      _vm.$slots.default /* eslint-disable-line @gitlab/vue-prefer-dollar-scopedslots */
    )?_c('gl-tooltip',{attrs:{"target":function () { return _vm.$refs.userAvatarImage; },"placement":_vm.tooltipPlacement,"boundary":"window"}},[_vm._t("default",[_vm._v(_vm._s(_vm.tooltipText))])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }